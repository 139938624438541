import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import config from '../conf/config';
import './loginstyle.css';
const Api = "https://www.example.com/auth/login.php";
console.log("OLD API=> " + Api)

// Replace domain name with the one from config
const newApi = Api.replace('www.example.com', config.domain);
console.log("NEW API URL=> " + newApi)

const Register = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleFetchApi = (req) => {
    axios.post(newApi, req)
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("userName", res.data.name);
          localStorage.setItem("Token", res.data.token);
          navigate("/dashboard");
        }
      })
      .catch((err) => console.log(err.message));
  };

  const handleLogin = () => {
    const update = {
      email: userName,
      password: password
    };
    handleFetchApi(update);
  };

  return (
    <div className="dark-page py-5">
      <Container>
        <Row className="my-5 justify-content-md-center">
          <Col xs={12} md={6}>
            <div className="signin">
              <div className="content">
                <div className='mb-5'><img src="https://api.sarinformatics.in/portfolio/public/images/sar_informatics.png" id='logo' height={100} alt="logo" /></div>
                <h2>Sign In</h2>
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={userName} onChange={(e) => setUserName(e.target.value)} />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </Form.Group>
                  <Button className="my-3 btn btn-lg" variant="primary" type="button" onClick={handleLogin}>
                    Sign In
                  </Button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Register;
