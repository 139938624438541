import React, { useEffect, useState } from 'react';
import './Dashboard.css'; // Assuming you have a CSS file for styling
import Overview from '../pages/Overview';
import NewBlog from '../pages/CreateBlog';
import AllItems from '../components/TabulerBlog';
import Logout from '../pages/Logout';
import AboutPage from '../pages/AboutPage';
import DisclaimerPage from '../pages/DisclaimerPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import FAQPage from '../pages/FAQPage';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Testimonials from '../pages/Testimonials';
import NewProject from '../pages/NewProject'; // Import the NewProject component

const Dashboard = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [currentPage, setCurrentPage] = useState('Overview');

  useEffect(() => {
    const user1Name = localStorage.getItem("userName");
    if (user1Name) {
      const firstName = user1Name.split(' ')[0]; // Extracting the first name
      setName(firstName);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const changePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="dashboard">
        <div className="sidebar">
          <ul>
            <li>
              <Link to="/" className="navbar-brand">
                <img src="https://api.sarinformatics.in/portfolio/public/images/logo.png" id='logo' height={60} alt="logo" />
              </Link>
            </li>
            <li className={currentPage === 'Overview' ? 'active' : ''} onClick={() => changePage('Overview')}>Overview</li>
            <li className={currentPage === 'NewBlog' ? 'active' : ''} onClick={() => changePage('NewBlog')}>New Post</li>
            <li className={currentPage === 'NewProject' ? 'active' : ''} onClick={() => changePage('NewProject')}>New Project</li>
        
            <li className={currentPage === 'AllItems' ? 'active' : ''} onClick={() => changePage('AllItems')}>All Posts</li>
            <hr></hr>
            <li className={currentPage === 'Testimonials' ? 'active' : ''} onClick={() => changePage('Testimonials')}>Testimonials</li>
            <li className={currentPage === 'AboutPage' ? 'active' : ''} onClick={() => changePage('AboutPage')}>About</li>
            <li className={currentPage === 'DisclaimerPage' ? 'active' : ''} onClick={() => changePage('DisclaimerPage')}>Disclaimer</li>
            <li className={currentPage === 'PrivacyPolicyPage' ? 'active' : ''} onClick={() => changePage('PrivacyPolicyPage')}>Privacy Policy</li>
            <li className={currentPage === 'FAQPage' ? 'active' : ''} onClick={() => changePage('FAQPage')}>FAQ</li>
           <hr></hr>
            <li className='text-success'>Logged in as <b>{name}</b></li>
            <li className={currentPage === 'Logout' ? 'active' : ''} onClick={() => changePage('Logout')}>Logout</li>
          </ul>
        </div>
        <div className="main-content">
          {currentPage === 'Overview' && <Overview />}
          {currentPage === 'NewBlog' && <NewBlog />}
          {currentPage === 'NewProject' && <NewProject />} 
          {currentPage === 'AllItems' && <AllItems />}
          
          {currentPage === 'Testimonials' && <Testimonials />}
          {currentPage === 'AboutPage' && <AboutPage />}
          {currentPage === 'DisclaimerPage' && <DisclaimerPage />}
          {currentPage === 'PrivacyPolicyPage' && <PrivacyPolicyPage />}
          {currentPage === 'FAQPage' && <FAQPage />}
          {currentPage === 'Logout' && <Logout/>}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
