import React from 'react';
// import IntroSectionIllustration from '';
import { Link } from 'react-router-dom';

const Login = () => {
  return (
    <div id="home" className="intro-section">
      <div className="container">
        <div className="row mx-5 mb-5 p-5 align-items-center text-white">
          {/* START THE CONTENT FOR THE INTRO */}
          <div className="col-md-6 intros text-start">
            <div className='mb-5'><img src="https://api.sarinformatics.in/portfolio/public/images/sar_informatics.png" id='logo' height={100} alt="logo" /></div>
          
            <h1 className="display-2">
              {/* <IntroSectionIllustration/> */}

              <span className="display-2--intro">Hello Admin
</span>
              <span className="display-2--description lh-base">
              Please authenticate to get access to Admin Panel 
                
              </span>
            </h1>
            
            <button className="btn btn-light px-5 py-3">
              <Link to={'/login'}>Login
              <span>
                <i className="fas fa-arrow-right"></i>
              </span></Link>
            </button>
          </div>
         
       
        </div>
      </div>
    
    </div>
  );
};

export default Login;
