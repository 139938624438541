import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
`;

const Form = styled.form`
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  max-width: 500px;
  width: 100%;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  &:hover {
    background-color: #0056b3;
  }
`;

const ResponseContainer = styled.div`
  margin-top: 2rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: left;
  font-family: monospace;
  white-space: pre-wrap;
`;

const IndexNowComponent = () => {
  const [url, setUrl] = useState('');
  const [response, setResponse] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      host: "www.sarinformatics.in",
      key: "c48b7023c0b14f35a9449a2b2140a042",
      keyLocation: "https://www.sarinformatics.in/c48b7023c0b14f35a9449a2b2140a042.txt",
      urlList: [url]
    };

    try {
      const res = await axios.post('https://api.indexnow.org/IndexNow', payload, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Host': 'www.bing.com'
        }
      });

      setResponse(res.data);
    } catch (error) {
      console.error('Error:', error);
      setResponse({ error: 'Something went wrong!' });
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Title>Submit URL to IndexNow</Title>
        <Input
          type="text"
          placeholder="Enter URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          required
        />
        <Button type="submit">Submit</Button>
      </Form>

      {response && (
        <ResponseContainer>
          <h3>Response:</h3>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </ResponseContainer>
      )}
    </Container>
  );
}

export default IndexNowComponent;
