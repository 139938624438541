import React, { useState, useEffect } from 'react';
import HTMLRenderer from './Renderer';
import axios from 'axios';
import './Existing.css';
import Message from '../components/Popup.js';
import config from '../conf/config.js';

const Api = "https://api.sarinformatics.in/privacy-policy"; // API URL for Privacy Policy page
const ApiData = Api.replace('api.sarinformatics.in', config.domain); // Replacing domain placeholder with actual domain

const PrivacyPolicy = () => {
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [deletedStatus, setDeletedDStatus] = useState(null);
    const [deletedMessage, setDeletedMessage] = useState(null);

    const [privacyData, setPrivacyData] = useState(null);

  useEffect(() => {
    fetch('https://api.sarinformatics.in/public/pages/privacy.php')
      .then(response => response.json())
      .then(data => {
        setPrivacyData(data.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

    const handlePostData = (data) => {
        axios.post(ApiData, data)
            .then((response) => {
                console.log("Data is Processing");
                console.log(response.data);
                setShowPopup(true);
                setTimeout(() => {
                    window.location.reload(); // Reload page after 5 seconds
                }, 7000); // 7000 milliseconds = 7 seconds

                const status = response.data.status;
                if (status === 200) {
                    setDeletedDStatus("success");
                } else {
                    setDeletedDStatus("error");
                }
                const msg = response.data.message;
                setDeletedMessage(msg);
            })
            .catch((error) => {
                console.log("Data is Not Processing");
                console.log(error.message);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            id: id,
            title: title,
            content: content
        };
        handlePostData(data);

        // Clear input fields after submission
        setId('');
        setTitle('');
        setContent('');
    };

    return (
        <div className='row'>
            <div className='col-6'>
            <div className='privacy-policy-container'>
            <h2 className="fs-1 text-center">Privacy Policy</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label fw-bold" htmlFor="id">ID</label>
                    <input
                        type="text"
                        className="form-control"
                        id="id"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label fw-bold" htmlFor="title">Title</label>
                    <input
                        type="text"
                        className="form-control"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label fw-bold" htmlFor="content">Content</label>
                    <textarea
                        className="form-control"
                        id="content"
                        rows="4"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        required
                    ></textarea>
                </div>
                <div className="my-3">
                    <button type="submit" className="btn btn-lg btn-primary">Submit</button>
                    {showPopup && <Message type={deletedStatus} message={deletedMessage} />}
                </div>
            </form>
        </div>
            </div>
            <div className='col-6 p-3 existing-container'>
            {privacyData && privacyData.map(item => (
          <div key={item.id} className="row align-items-center pb-5">
            <h2 className="display-5 fw-bold text-capitalize text-dark mt-3">{item.subtitle}</h2>
            <span className="display-2--description lh-base text-dark mb-3"><HTMLRenderer htmlString={item.content} /></span>
          </div>
        ))}
               
            </div>
        </div>
        
    );
};

export default PrivacyPolicy;
