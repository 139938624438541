import React, { useState } from 'react';
import axios from 'axios';
import config from '../conf/config'

function FileUpload({setUploadpath}) {
  const [file, setFile] = useState("");
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const Api="https://www.example.com/demo/upload.php"
    const newApi = Api.replace('www.example.com', config.domain);
    console.log("NEW API URL=> "+newApi)

    try {
      const response = await axios.post(newApi, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('File uploaded:', response.data);
      setUploadStatus('File uploaded successfully.');
      const path=response.data.path;
      console.log(path);
      setUploadpath(path);
        } catch (error) {
      console.error('Error uploading file:', error);
      setUploadStatus('Error uploading file. Please try again.');
    }
  };

  return (
    <div className='my-4 p-3'>
      
      <input type="file" onChange={handleFileChange} />
      <button className="p-2" onClick={handleUpload}>Upload</button>
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
}

export default FileUpload;
