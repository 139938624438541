import React from 'react';
import './DashboardOverview.css';
import IndexNow from '../components/IndexNow';                      

const DashboardOverview = () => {
    // Assuming these values are dynamically fetched from a database or an API
    const totalUsers = 1000;
    const totalSales = 50000;
    const averageRating = 4.5;
    const newOrders = 200;

    return (
        <div className="dashboard-overview">
            
            <div className="overview-cards">
                <div className="overview-card">
                    <h3>Total Users</h3>
                    <p>{totalUsers}</p>
                </div>
                <div className="overview-card">
                    <h3>Total Sales</h3>
                    <p>${totalSales.toLocaleString()}</p>
                </div>
                <div className="overview-card">
                    <h3>Average Rating</h3>
                    <p>{averageRating}/5</p>
                </div>
                <div className="overview-card">
                    <h3>New Orders</h3>
                    <p>{newOrders}</p>
                </div>
            </div>
            <IndexNow/>
        </div>
    );
};

export default DashboardOverview;
