import React, { useState, useEffect } from 'react';
import HTMLRenderer from './Renderer';
import axios from 'axios';
import './Existing.css';
import Message from '../components/Popup.js';
import config from '../conf/config.js';

const Api = "https://api.sarinformatics.in/faq"; // API URL for FAQ page
const ApiData = Api.replace('api.sarinformatics.in', config.domain); // Replacing domain placeholder with actual domain

const FAQPage = () => {
    const [id, setId] = useState('');
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [deletedStatus, setDeletedDStatus] = useState(null);
    const [deletedMessage, setDeletedMessage] = useState(null);

    const [faqData, setFaqData] = useState(null);

    useEffect(() => {
      fetch('https://api.sarinformatics.in/public/pages/faq.php')
        .then(response => response.json())
        .then(data => {
          setFaqData(data.data);
        })
        .catch(error => console.error('Error fetching FAQ data:', error));
    }, []);

    const handlePostData = (data) => {
        axios.post(ApiData, data)
            .then((response) => {
                console.log("Data is Processing");
                console.log(response.data);
                setShowPopup(true);
                setTimeout(() => {
                    window.location.reload(); // Reload page after 5 seconds
                }, 7000); // 7000 milliseconds = 7 seconds

                const status = response.data.status;
                if (status === 200) {
                    setDeletedDStatus("success");
                } else {
                    setDeletedDStatus("error");
                }
                const msg = response.data.message;
                setDeletedMessage(msg);
            })
            .catch((error) => {
                console.log("Data is Not Processing");
                console.log(error.message);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            id: id,
            question: question,
            answer: answer
        };
        handlePostData(data);

        // Clear input fields after submission
        setId('');
        setQuestion('');
        setAnswer('');
    };

    return (
        <div className='row'>
            <div className='col-6'>
            <div className='faq-container'>
            <h2 className="fs-1 text-center">FAQ</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label fw-bold" htmlFor="id">ID</label>
                    <input
                        type="text"
                        className="form-control"
                        id="id"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label fw-bold" htmlFor="question">Question</label>
                    <input
                        type="text"
                        className="form-control"
                        id="question"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label fw-bold" htmlFor="answer">Answer</label>
                    <textarea
                        className="form-control"
                        id="answer"
                        rows="4"
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        required
                    ></textarea>
                </div>
                <div className="my-3">
                    <button type="submit" className="btn btn-lg btn-primary">Submit</button>
                    {showPopup && <Message type={deletedStatus} message={deletedMessage} />}
                </div>
            </form>
        </div>
            </div>
        
            <div className='col-6 p-3 existing-container'>
            {faqData && faqData.map(item => (
          <div key={item.id} className="row align-items-center py-5 faq-container">
            <h2 className="display-7 fw-bold text-capitalize">{item.subtitle}</h2>
            <span className="display-2--description lh-base text-dark mb-3"><HTMLRenderer htmlString={item.content} /></span>
          </div>
        ))}

            </div>
        </div>
        
    );
};

export default FAQPage;
