import React, { useEffect, useState } from 'react';
import TipTopBar from './TipTopBar';

function MainTipTop({ setContent }) {
    const [description, setDescription] = useState("");

    useEffect(() => {
        // Only run this effect when 'description' changes
        setContent(description);
    }, [description, setContent]);

    return (
        <div>
            <TipTopBar setDescription={setDescription} />
        </div>
    )
}

export default MainTipTop;
