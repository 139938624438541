import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainTipTop from '../components/Tooltip/MainTipTop.jsx';

import FileUpload from '../components/FileUpload.jsx';
import Message from '../components/Popup.js';
import config from '../conf/config.js';

const Api = "https://www.example.com/auth/create_project.php"
const ApiData = Api.replace('www.example.com', config.domain);
console.log("NEW API URL=> " + ApiData)





const CreateBlog = () => {
    const [title, setTitle] = useState('');
    const [subTitle, setsubTitle] = useState('');
    const [tags, setTags] = useState('');
    const [catagory, setCatagory] = useState("");
    const [content, setContent] = useState("");
    const [token, setToken] = useState("");
    const [status, setStatus] = useState("");
    const [uploadimgpath, setUploadimgpath] = useState("");
    const [uploadpptpath, setUploadpptpath] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [deletedStatus, setDeletedDStatus] = useState(null);
    const [deletedMessage, setDeletedMessage] = useState(null);



    // Function to receive content from MainTipTop
    const handlecontentValue = (newcontent) => {
        setContent(newcontent);

    }

    //For show Data in Api
    const handlePostData = (res) => {
        axios.post(ApiData, res)
            .then((e) => {
                console.log("Data is Processing");
                // console.log(e);
                console.log(e.data);
                // if (e.data.status === 200) {
                //     window.alert(e.data.message);
                // }




                setShowPopup(true);
                setTimeout(() => {
                    window.location.reload(); // Reload page after 5 seconds
                }, 7000); // 5000 milliseconds = 5 seconds

                const status = e.data.status;
                console.log(status);
                if (status === 200) {
                    setDeletedDStatus("success")

                }
                else {
                    setDeletedDStatus("error");

                }
                const msg = e.data.message;
                console.log(msg);
                setDeletedMessage(msg)






            })
            .catch((err) => {
                console.log("Data is Not Processing");
                console.log(err.message);
            })
    }

    ////For to Store Token From LocalStorage....

    useEffect(() => {
        const StorageData = localStorage.getItem("Token");
        if (StorageData) {
            setToken(StorageData);

        }

    }, [token]);




    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };




    const handleSubmit = (event) => {
        event.preventDefault();
        // You can add logic here to handle submitting the blog post data
        console.log('Title:', title);
        console.log('SubTitle:', subTitle);
        console.log('Tags:', tags);
        console.log('Catagory:', catagory);
        console.log("content:", content);
        console.log("token", token);
        console.log("status", status);
        console.log("File", uploadimgpath);
        //
        const Data = {
            title: title,
            subtitle: subTitle,
            content: content,
            tags: tags,
            category: catagory,
            img_url: uploadimgpath,
            ppt_url: uploadpptpath,
            status: status,
            auth_token: token
        }
        handlePostData(Data);

        // Clear input fields after submission
        setTitle('');
        setsubTitle("");
        setCatagory("");
        setTags("");





    };

    return (
        <>
            <div className='row'>
            <h2 className='fs-1 text-center'>New Post</h2>
                <div className='col-8'>
                    <div className="create-blog">
                       
                        <form onSubmit={handleSubmit}>
                            <div className=" mb-1">
                                <label class="form-label  fw-bold " htmlFor="title">Title</label>
                                <input
                                    type="text" className="form-control"
                                    id="title"
                                    value={title}
                                    onChange={handleTitleChange}
                                    required
                                /><br />
                            </div>

                            <div className=" mb-1">
                                <label class="form-label fw-bold" htmlFor="subTitle">SubTitle</label>
                                <input
                                    type="text" className="form-control"
                                    id="SunbTitle"
                                    onChange={(e) => setsubTitle(e.target.value)}
                                    value={subTitle}
                                    required
                                />
                            </div>

                            <div className="mb-1">
                                <label class="form-label fw-bold" for="Category">Category</label>
                                <select name="Category" id="Category" className="form-control" onChange={(e) => setCatagory(e.target.value)}>
                                    <option value="Project">Choose Catagory</option>

                                    <option value="Project">Project</option>
                                </select>
                            </div>
                            <div>
                                <label class="form-label fw-bold" htmlFor="content">Content</label>
                                <MainTipTop setContent={handlecontentValue} />
                            </div>
                            <div className="mb-3">
                                <label class="form-label fw-bold" htmlFor="Tags">Tags</label>
                                <input
                                    type="text" className="form-control"
                                    id="Tags"
                                    onChange={(e) => setTags(e.target.value)}
                                    value={tags}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold" htmlFor="fileURL">File or Image URL</label>
                                <div class="input-group">
                                    <input
                                        type="text" className="form-control"
                                        id="responce"
                                        value={uploadimgpath}
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold" htmlFor="fileURL">PPT URL</label>
                                <div class="input-group">
                                    <input
                                        type="text" className="form-control"
                                        id="responce"
                                        value={uploadpptpath}
                                    />
                                </div>
                            </div>
                            <div className="mb-1">
                                <label class="form-label fw-bold" for="Category">Status</label>
                                <select name="Category" id="Category" className="form-control" onChange={(e) => setStatus(e.target.value)}>
                                    <option value="published">Publish</option>
                                    <option value="draft">Save as Draft</option>
                                </select>
                            </div>


                            <div>

                            </div>


                            <div className='my-4'>
                                <button type="submit" class="btn btn-lg btn-primary "> Save Post</button>
                                {showPopup && <Message type={deletedStatus} message={deletedMessage} />}
                            </div>



                        </form>


                    </div>
                </div>
                <div className='col-4'>
                <div className='m-2 py-3 btn btn-warning'>
                                <h6>Upload Image</h6>
                                <FileUpload setUploadpath={setUploadimgpath} />
                            </div>
                            <div className='m-2 py-3 btn btn-warning'>
                                <h6>Upload PPT</h6>
                                <FileUpload setUploadpath={setUploadpptpath} />
                            </div>
                </div>

            </div>

        </>
    );
};

export default CreateBlog;
