import React, { useState } from 'react';

function YourComponent({ editor }) {
  const [href, setHref] = useState('https://example.com');
  const [showModal, setShowModal] = useState(false);

  const handleLinkClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleSaveHref = () => {
    // Here you can perform any validation or manipulation on the href value if needed
    setShowModal(false);
    // Set the href value in the editor or wherever needed
    editor.chain().focus().toggleLink({ href }).run();
  };

  return (
    <span className='bg-link'>
      <button onClick={handleLinkClick} >
        <b> Link</b>
      </button>
      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Link</h5>
                <button type="button" className="close" onClick={handleModalClose} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <label htmlFor="hrefInput">New href:</label>
                <input id="hrefInput" type="text" value={href} onChange={(e) => setHref(e.target.value)} />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleModalClose}>Close</button>
                <button type="button" className="btn btn-primary" onClick={handleSaveHref}>Save changes</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </span>
  );
}

export default YourComponent;
