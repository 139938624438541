import React from 'react';

const LogoutPage = () => {
    // Add any logout logic here, such as clearing local storage, redirecting, etc.
    const handleLogout = () => {
        // Example: Clearing local storage
        localStorage.clear();
        // Example: Redirecting to the login page
        window.location.href = '/login'; // You might need to adjust this URL
    };

    return (
        <div className="logout-page">
            <h2>Logout</h2>
            <p>Are you sure you want to logout?</p>
            <button onClick={handleLogout} className='px-3 py-1 bg-danger text-light logoutbtn'>Logout</button>
        </div>
    );
};

export default LogoutPage;
