import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DeleteButton from './Deletebtn';
import EditBlog from './EditBlog';
import './PaginationComponent.css'; 
import config from '../conf/config';


function PaginationComponent() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 5;

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

 

  const fetchData = async (page) => {
    try {
      const Api="https://www.example.com/auth/opr/get_all_posts.php"
      const newApi = Api.replace('www.example.com', config.domain);
      console.log("NEW API URL=> "+newApi)

      const response = await axios.get(`${newApi}?page=${page}&per_page=${perPage}`);
      // console.log(response.data.data);
      setPosts(response.data.data);
      // console.log(posts);
      const page_count = Math.floor(response.data.total_count / perPage);
      setTotalPages(page_count + 1);
      // console.log(page_count);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
//Next Page In paggination
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
//Previous Page In paggination
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  


  return (
    <div>
      <table className="styled-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Status</th>
            <th>Category</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>




        <tbody>
  {posts.map((ele) => {
    // Define a variable to hold the class name based on the status
    let statusClass = '';
    if (ele.status === 'deleted') {
      statusClass = 'deleted-post';
    }
    else{
      statusClass = 'active-post';
    }

    return (
      <tr key={ele.id} className={statusClass}>
        <td>{ele.title}</td>
        <td>{ele.status}</td>
        <td>{ele.category}</td>
        <td>{ele.formatted_date}</td>
        <td>
          {ele.status !== 'deleted' && <DeleteButton id={ele.id} fetchData={fetchData} />}
          <EditBlog id={ele.id} />
        </td>
      </tr>
    );
  })}
</tbody>






      </table>
      <div className="mt-4 grid-container">
        <button className='left-btn' onClick={handlePrevPage} disabled={currentPage === 1}>Previous</button>
        <button  className='right-btn' onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
      </div>
    </div>
  );
}

export default PaginationComponent;
