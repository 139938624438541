import React, { useEffect, useState } from 'react';
// import HTMLRenderer from './Renderer';
import axios from 'axios';
import './Existing.css';

import FileUpload from '../components/FileUpload.jsx';
import Message from '../components/Popup.js';
import config from '../conf/config.js';

const Api = "https://api.sarinformatics.in/auth/create_testimonial.php"; // Updated API URL
const ApiData = Api.replace('api.sarinformatics.in', config.domain); // Replacing domain placeholder with actual domain

const CreateTestimonial = () => {
    const [quote, setQuote] = useState('');
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [token, setToken] = useState('');
    const [uploadpath, setUploadpath] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [deletedStatus, setDeletedDStatus] = useState(null);
    const [deletedMessage, setDeletedMessage] = useState(null);


    const [testimonials] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
  
    const slideNext = () => {
      const newIndex = (activeIndex + 1) % testimonials.length;
      setActiveIndex(newIndex);
    };

    console.log(slideNext)

    // const slideNext = () => {
    //     const newIndex = (activeIndex + 1) % testimonials.length;
    //     setActiveIndex(newIndex);
    //   };
  
  

    //For show Data in Api
    const handlePostData = (res) => {
        axios.post(ApiData, res)
            .then((response) => {
                console.log("Data is Processing");
                console.log(response.data);
                setShowPopup(true);
                setTimeout(() => {
                    window.location.reload(); // Reload page after 5 seconds
                }, 7000); // 7000 milliseconds = 7 seconds

                const status = response.data.status;
                if (status === 200) {
                    setDeletedDStatus("success");
                } else {
                    setDeletedDStatus("error");
                }
                const msg = response.data.message;
                setDeletedMessage(msg);
            })
            .catch((error) => {
                console.log("Data is Not Processing");
                console.log(error.message);
            });
    };

    // For storing token from localStorage
    useEffect(() => {
        const storedToken = localStorage.getItem("Token");
        if (storedToken) {
            setToken(storedToken);
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            quote: quote,
            name: name,
            role: role,
            image: uploadpath,
            auth_token: token
        };
        handlePostData(data);

        // Clear input fields after submission
        setQuote('');
        setName('');
        setRole('');
    };

    return (
        <>
        <div className='row'>
            <div className='col-6 p-3'>
            <div className="create-testimonial">
            <h2 className="fs-1 text-center">New Testimonial</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label fw-bold" htmlFor="quote">Quote</label>
                
                    <input
                        type="text"
                        className="form-control"
                        id="quote"
                        value={quote}
                        onChange={(e) => setQuote(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label fw-bold" htmlFor="name">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label fw-bold" htmlFor="role">Role</label>
                    <input
                        type="text"
                        className="form-control"
                        id="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        required
                    />
                </div>
    

                <div className="mb-3">
                    <label className="form-label fw-bold" htmlFor="uploadpath">Upload Path</label>
                    <input
                        type="text"
                        className="form-control"
                        id="uploadpath"
                        value={uploadpath}
                        readOnly
                    />
                </div>
                
                <div className="my-3">
                    <button type="submit" className="btn btn-lg btn-primary">Submit</button>
                    {showPopup && <Message type={deletedStatus} message={deletedMessage} />}
                </div>
            </form>
            <FileUpload setUploadpath={setUploadpath} />
        </div>

            </div>
            <div className='col-6 p-3 existing-container'>
            {testimonials.map((testimonial, index) => (
                <div key={index} className={`carousel-item ${index === activeIndex ? 'active' : ''}`}>
                  <div className="testimonials__card">
                    <p className="lh-lg">
                      <i className="fas fa-quote-left"></i>
                      {testimonial.quote}
                      <i className="fas fa-quote-right"></i>
                      <div className="ratings p-1">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </p>
                  </div>
                  <div className="testimonials__picture">
                    <img src={testimonial.image} alt={`client-${index + 1}`} className="rounded-circle img-fluid" />
                  </div>
                  <div className="testimonials__name">
                    <h3>{testimonial.name}</h3>
                    <p className="fw-light">{testimonial.role}</p>
                  </div>
                </div>
              ))}
            </div>
        </div>
        
        </>
    );
};

export default CreateTestimonial;

