
import './Popup.css'; // Import CSS file for styling

import React, { useState } from 'react';


const Message = ({type, message }) => {
  const [isVisible, setIsVisible] = useState(true);


  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className={`message ${type}`}>
          <span className="close" onClick={handleClose}>&times;</span>
          <p>{message}</p>
        </div>
      )}
    </>
  );
};

export default Message;

