import axios from 'axios';
import React from 'react'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import NewTiptopBar from "./Tooltip/MainTipTop"
import FileUpload from '../components/FileUpload';


const ApiGetUpdate = "https://api.sarinformatics.in/auth/opr/get_update_post.php"

const EditBlog = ({ id }) => {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [newsubTitle, setNewSubTitle] = useState("");
  const [newcategory, setNewcategory] = useState("");
  const [newcontent, setNewcontent] = useState("");
  const [newTag, setNewTag] = useState("");
  const [uploadpath, setUploadpath] = useState("");




  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    getData();
  }
  const postId = id;

  const getData = () => {
    axios.get(`${ApiGetUpdate}?id=${postId}`)
      .then((res) => {
        console.log(res.data);
        const title = res.data.data.title;
        const subTitle = res.data.data.subtitle;
        const category = res.data.data.category;
        const content = res.data.data.content;
        const tag = res.data.tags.tags;


        console.log("Title:", title);
        console.log("SubTitle:", subTitle);
        console.log("Category:", category);
        console.log("Content:", content);
        console.log("Tag:", tag);

        setNewTitle(title);
        setNewSubTitle(subTitle);
        setNewcategory(category);
        console.log(newcategory);
        setNewcontent(content)
        setNewTag(tag);
      })
      .catch((err) => console.log(err.message))
  }
  // Function to receive content from MainTipTop
  const handlecontentValue = (a) => {
    setContent(a);
    console.log(content);

  }
  // console.log(newcontent);

  return (
    <div>
      <button onClick={handleShow} className="action-button edit">Edit</button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-lg" // Use "modal-lg" for large modal
        aria-labelledby="myLargeModalLabel"
        centered // To center the modal vertically
      >
        <Modal.Header closeButton>
          <Modal.Title id="myLargeModalLabel">Large Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {postId}
          <div>
            <div className=" mb-1">
              <label class="form-label  fw-bold " htmlFor="title">Title</label>
              <input
                type="text" className="form-control "
                id="title"
                value={newTitle}
                required
              /><br />
            </div>
            <div className=" mb-1">
              <label class="form-label fw-bold" htmlFor="subTitle">SubTitle</label>
              <input
                type="text" className="form-control"
                id="SunbTitle"
                value={newsubTitle}
                required
              />
            </div>
            <div className="mb-1">
              <label class="form-label fw-bold" for="Category">Category:-</label>
              <select name="Category" id="Category" className="form-control" >
                <option value="Blog">Blog</option>
                <option value="Project">Project</option>
              </select>
            </div>

            {/* Coding field */}
            <div>
              <label class="form-label fw-bold" htmlFor="content">Content:-</label>
              <NewTiptopBar value={newcontent}
                setContent={handlecontentValue}
              />
            </div>

            <div className="mb-3">
              <label class="form-label fw-bold" htmlFor="Tags">Tags:-</label>
              <input
                type="text" className="form-control"
                id="Tags"
                value={newTag}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label fw-bold" htmlFor="fileURL">File or Image URL:-</label>
              <div class="input-group">
                <input
                  type="text" className="form-control"
                  id="responce"
                  value={uploadpath}

                  // value={tags}
                  required
                />
              </div>
            </div>
          </div>
          <div>
            <FileUpload setUploadpath={setUploadpath} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default EditBlog
