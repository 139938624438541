import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Message from './Popup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import config from '../conf/config';

const Api = "https://www.example.com/auth/opr/delete_post.php"
const ApiDelete = Api.replace('www.example.com', config.domain);
console.log("NEW API URL=> " + ApiDelete)



function DeleteButton({ id, fetchData }) {
  const [authToken, setAuthToken] = useState("");
  const [currentPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [deletedStatus, setDeletedStatus] = useState(null);
  const [deletedMessage, setDeletedMessage] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const perPage = 5;

  useEffect(() => {
    fetchData1(currentPage);
  }, [currentPage, fetchData]);

  useEffect(() => {
    const data = localStorage.getItem("Token");
    if (data) {
      setAuthToken(data);
    }
  }, []);

  const fetchData1 = async (page) => {
    try {
      await axios.get(`https://api.sarinformatics.in/auth/opr/get_all_posts.php?page=${page}&per_page=${perPage}`);
      // const pageCount = Math.floor(response.data.total_count / perPage);
      // Update totalPages here if needed
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle errors here if needed
    }
  };

  const handleDeleteConfirmation = () => {
    axios.post(ApiDelete, { post_id: id, auth_token: authToken })
      .then((res) => {
        setShowConfirmationModal(false);
        setShowPopup(true);
        setDeletedStatus(res.data.status === 200 ? "success" : "error");
        setDeletedMessage(res.data.message);
        fetchData1(currentPage); // Fetch data for the current page after deletion
        setTimeout(() => {
          window.location.reload(); // Reload page after 5 seconds
        }, 5000); // 5000 milliseconds = 5 seconds

      })
      .catch((err) => {
        console.error("Error deleting data:", err.message);
        // Handle error if needed
      });
  };

  const handleDelete = () => {
    setShowConfirmationModal(true);
  };

  return (
    <>
      <button className="action-button delete" onClick={handleDelete}>Delete</button>
      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
            No
          </Button>
          <Button variant="primary" onClick={handleDeleteConfirmation}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {showPopup && <Message type={deletedStatus} message={deletedMessage} />}
    </>
  );
}

export default DeleteButton;
