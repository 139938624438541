// App.js
import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';



import Dashboard from './components/Dashboard';
import Homepage from './Admin/LandingPage.js'
import Register from "./Admin/Login.jsx"
// import Navbar from '../src/components/Navbar.js';

// import ToolBar from './components/DashboardComponent/Toolbar.jsx';



const App = () => {
  return (
    <Router>
       
      
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
       

      </Routes>
   
     
    </Router>
    
    

  );
};

export default App;
